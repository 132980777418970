.banner-sect-1{
    background-image: url("../images/bannersect1.png");
    background-position: right;
    background-size: 56%;
    background-repeat: no-repeat;
}

@media screen and (min-width:980px) and (max-width: 1030px){
    .banner-sect-1 {
        background-size: 60%;
    }}

    @media screen and (min-width:370px) and (max-width: 527px){
        .banner-sect-1 {
            background-position: top;
            background-size: 110%;
        }}

        @media screen and (min-width:536px) and (max-width: 603px){
            .banner-sect-1 {
                background-position: top;
                background-size: 100%;
            }}

            @media screen and (min-width:603px) and (max-width: 710px){
                .banner-sect-1 {
                    background-position: top;
                    background-size: 200%;
                }}

            @media screen and (min-width:707px) and (max-width: 980px){
                .banner-sect-1 {
                    background-position: top;
                    background-size: 100%;
                }}

                @media screen and (min-width:603px) and (max-width: 707px){
                    .banner-sect-1 {
                        background-position: right;
                        background-size: 97%;
                    }}


.gradfont{
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 36px;

/* identical to box height */

background: linear-gradient(90deg, #182FFF 2.56%, #EB5741 101.28%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}

.sect-1-heading{
max-width: 570px;
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 62px;
color: #232D30;
}

.subheadings{
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: rgba(0, 0, 0, 0.7);
}

.sub-1-width{
    max-width: 582px;
}

.sect-1-button{
width: 190px;
height: 50px;
background: #EB5741;
border-radius: 2px;
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 400;
font-size: 18px;
color: white;
letter-spacing: 1px;
}


/* @media only screen and (min-width:370px) and (max-width: 520px){
    .sect-5-heading {
        width: 434px;
    }}


    @media screen and (min-width:520px) and (max-width: 664px){
        .sect-5-heading {
            width: 435px;
        }}

        @media screen and (min-width:664px) and (max-width: 1245px){
            .sect-5-heading {
                width: 582px;
            }} */

.sect-2-heading{
    max-width: 303px;
    font-family: 'Poppins-Black';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 62px;
    color: #232D30;
    }

    .sect-2-heading-2{
width: 250px;
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
color: #232D30;
text-align: center;
        }



.sec-2-cards{
height: 327px;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
border-bottom: 8px solid #EB5741;
}


.sect-2-img-1{
    background-image: url("../images/sect2img01.png");
    height: 77px;
    width: 90px;
}

.sect-2-img-2{
        background-image: url("../images/sect2img02.png");
        height: 77px;
    width: 90px;
}

.sect-2-img-3{
    background-image: url("../images/sect2img03.png");
    height: 77px;
    width: 90px;
}

.sect-2-img-4{
    background-image: url("../images/sect2img04.png");
    height: 77px;
    width: 90px;
}

.sect-2-img-5{
    background-image: url("../images/sect2img05.png");
    height: 77px;
    width: 90px;
}

.sect-2-img-6{
    background-image: url("../images/sect2img06.png");
    height: 77px;
    width: 90px;
}

.sect-2-img-7{
    background-image: url("../images/sect2img07.png");
    height: 77px;
    width: 90px;
}

.sect-2-img-8{
    background-image: url("../images/sect2img08.png");
    height: 77px;
    width: 90px;
}

.w374{
    width: 374px;
}







/* @media screen and (min-width:375px) and (max-width: 480px){
    .api-sec-3-heading{
        width: 299px;
    }}

    @media screen and (min-width:480px) and (max-width: 574px){
        .api-sec-3-heading{
            width: 368px;
        }}

        @media screen and (min-width:575px) and (max-width: 574px){
            .api-sec-3-heading{
                width: 485px;
            }} */





.bg-sect-4{
    background-image: url("../images/sec4bg1.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #0316bb;
}

.sect-4-heading{
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 500;
font-size: 44px;
padding-top: 55px;
color: #FFFFFF;
}

.colwhite{
    color: #FFFFFF;
}


.sect-4-heading-2{
width: 329px;
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 36px;
color: #FFFFFF;
}

.sect-4-subheadcol{
    color: white;
    max-width: 272px;
}


.widthsub1{
max-width: 267px;
}

.sect-4-heading-3{

font-family: 'Poppins-Black';
font-style: normal;
font-weight: 400;
font-size: 34px;
line-height: 54px;

color: #ffffff;
}

.bg-2-sect-4{
    background-image: url("../images/sec4bg2.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #eb5741;
}


.sect-4-btn{
    width: 159px;
    height: 40px;
    
    background: #FFFFFF;
    border-radius: 4px;
}   


@media screen and (min-width:375px) and (max-width: 460px){
    .height-sect-4 {
        height: 1635px;
    }}

.height-sect-4{
    height: 590px;
}

.bottom138{
    bottom: -138px;
}

    .dcontents{
        display: contents;
    }


    @media screen and  (max-width: 991px){
        .height-sect-4 {
            height: 1582px;
        }}

        @media screen and (max-width: 991px){
            .bottom138 {
             bottom: -210px;
            }}


.sect-5-mtop{
margin-top: 210px;
}


@media screen and (max-width: 990px){
    .sect-5-mtop {
        margin-top: 152px;
    }}



.sect-5-heading{


font-family: 'Poppins-Black';
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 54px;

color: #000000;
}


.sec5widthsub{
    width: 358px;
}

.sec5imgsize{
    background-size: 85%;
}

.sec-6-bg{
    background-image: url("../images/sec6bgimg.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #ec5741;
}

.sec-6-heading{
width: 443px;
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 500;
font-size: 48px;
line-height: 72px;
text-align: center;

color: #000000;
}

.sec-6-heading-1{

    font-family: 'Poppins-Black';
font-style: normal;
font-weight: 500;
font-size: 48px;
line-height: 72px;
text-align: center;

color: #000000;

}

@media screen and (min-width:367px) and (max-width: 470px){
    .sec-6-heading-1 {
        color: #c7c7c7;
    }}


.sec-7-bg{
    background: linear-gradient(180deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("../images/sec7bgimg.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.sect-7-heading{
width: 292px;
font-family: 'Poppins-Black';
font-style: normal;
font-weight: 700;
font-size: 44px;
color: #FFFFFF;
}

.user{
    width: 266px;
height: 48px;
background: #FFFFFF;
border-radius: 6px;
margin: 8px;
border: none;
}

::-webkit-input-placeholder { 
    width: 96px;
    font-family: 'Poppins-Black';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    padding-left: 18px;
  }

.message{
margin-top: 6px;
padding-bottom: 72px;
width: 546px;
height: 112px;
background: #FFFFFF;
border-radius:  6px;
border: none;
}

.sect-7-button{
    width: 222px;
    height: 40px;
    background: #EB5741;
    border-radius: 6px;
    font-family: 'Poppins-Black';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: white;
    letter-spacing: 1px;
    }

    @media screen and (min-width:370px) and (max-width: 670px){
        .smalldev {
            flex-direction: column;
        }}

        @media screen and (min-width:370px) and (max-width: 670px){
            .message {
                width: 266px;
            }}

.bgfootcol{
    background-color: #000741;
}


.footer-heading{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 36px;
color: #FFFFFF;

}

.subheading-width-footer{
    width: 281px;
}

.w277{
    width: 277px;
}

.w110{
    width: 110px;
}

.bgwhite{
    background-color: white;
}


@font-face {
    font-family: "Poppins-Black.ttf";
    src: local("Poppins-Black.ttf"),
     url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
    font-weight: bold;
    }

/* Api Page */

.width496{
    width: 496px;
}

.whitecolor{
    color: white;
}

.api-sec-3-heading{
    font-family: 'Poppins-Black';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 72px;
    text-align: center;
    color: #000000;
}

.api-sec-5-heading{
    font-family: 'Poppins-Black';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    color: #000000;
}

.height-sect-5{
    height: fit-content;
}

.m5{
    margin: 5px;
}

.f17{
    font-size: 17px;
}

.bg-sec5-1{
    background-color: white;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    border-bottom: 8px solid #EB5741;
}

.w628{
    width: 628px;
}

.w479{
    max-width: 479px;
}

#sect-6-api-btn{
    margin: 1px;
    margin-bottom: 0px;
    padding: 14px 20px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #e3e3e3;
    background-color: #ebebeb;
    border: solid transparent;
    text-decoration: none;
}



.api-sec-6-heading{
    font-family: 'Poppins-Black';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 72px;
    text-align: center;
    color: #000000;
}


@media screen and (min-width:370px) and (max-width: 768px){
    .sect-6-btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }}





/*
flip card
*/

.colred{
    background-color: #eb5741;
}

.card-flip > div {
    backface-visibility: hidden;
    transition: transform 300ms;
    transition-timing-function: linear;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
  }
  
  .card-front {
    transform: rotateY(0deg);
  }
  
  .card-back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
  }
  
  .card-flip:hover .card-front {
    transform: rotateY(-180deg);
  }
    
  .card-flip:hover .card-back {
    transform: rotateY(0deg);
  }

.fs18{
    font-size: 18px;
}

.accord-btn{
    width: fit-content;
    text-align: left;
    font-style: 'Poppins-Black';
    /* background-color: #0316bb; */
    background-color: #f5f9ff;
    
    /* background: linear-gradient(90deg, #182FFF 2.56%, #EB5741 101.28%); */
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 19px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
}

.w604{
    width: 604px;
}

.width-fit-con{
    width: fit-content;
}

.card-custom{
    background-color: white;
    border: white;
}

.accord-btn:hover {
    background-color: #0316bb;
    color: white;
  }

  .accord-btn:visited {
    background-color: #0316bb;
    color: white;
  }

.accord-btn{
    width: 100%;
}

.close{
    color: white;
    padding-top: 30px;
}

.close:hover{
    cursor: pointer;
    color: #182FFF;
}

.f28{
    color: white;
    font-size: 28px;
}

.w194{
    width: 194px;
    font-size: 17px;
}

.getintouch-bg-1{
    height: 100%;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 10;
}

.w0{
    width: fit-content;
    color: white;
}

.getintouch-bg-2{
    background: linear-gradient(90deg, #182FFF 2.56%, #EB5741 101.28%);
    border-radius: 8px;
}

.w698{
    max-width:  698px;
    font-size: 18px
}

.w621{
    max-width: 628px;
    font-size: 18px;
}

.w240{
    width: 240px;
    font-size: 17px;
}

.w638{
    width: 638px;
}

.f22{
    font-size: 22px;
    color: rgb(236, 236, 236);
}

.colblack{
    color: black;
}

.w343{
    width: 343px;
}

.close {
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
}

